import { PROMOTIONAL_PLANNING_PROMO_LOCATION_LIST } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Activity List", "search-by": "CustomerName,Description", "ph-search-by": "Search by Key Account and Activity" } }, [_c("url-field", { key: "CustomerName", attrs: { "base-url": "/promotion-planning/activity-list", "data-index": "customerName", "title": "Key Account", "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Activity", "sorter": true } }), _c("text-field", { key: "Rate", attrs: { "data-index": "rate", "parse": _vm.parseRate, "title": "Rate", "sorter": true } }), _c("text-field", { key: "category", attrs: { "data-index": "category", "title": "Category", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "LocationList",
  methods: {
    parseRate(rate) {
      return rate + "%";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListLocation = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-location" }, [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "promotion-planning.primary-instore", "api-url": _vm.apiUrl, "create-route": "/promotion-planning/activity-list/create", "edit-route": "/promotion-planning/activity-list/:id", "page": _vm.page } }, [_c("list-location")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListLocation
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_LOCATION_LIST,
      menuItems: [
        {
          title: "Master Files",
          path: ""
        },
        {
          title: "Activity List",
          path: "/promotion-planning/activity-list"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
